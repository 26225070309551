import {
  useNavigate,
} from "react-router-dom";
import { analyticsLog } from './FirebaseAPI';
import { useEffect } from 'react';

export function Chocolate(){
  const navigate = useNavigate();
  analyticsLog("chocolate_url_visited")
  useEffect(()=>{
    navigate("/")
  })
}
