
import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import "./InfoMenu.css"
import { viberUrl } from './common';


export function InfoButton(){
    const [closed, setClosed] = useState(true)

    return <Fragment>
            <div className={'cover'+(closed?" cover-gone":"")} onClick={() => { setClosed(true) }}></div> 
            <button onClick={() => { setClosed(!closed) }} className={"info-btn info-show-small"+(closed?"":" checked over-cover")}>
                Меню
            </button>
            <div className='info-menu'>
                <div className={'info-menu-content' + (closed ? " hidden-top" : "")} onClick={() => { setClosed(true) }}>
                    <Link to={viberUrl} className="viber-menu-item" id="viber-in-menu">
                        <div className='img-wrapper'><img alt="viber" src="/icons/viber_logo.svg" /></div>
                        +38 063 634 57 23
                    </Link>
                    <Link to="https://annaiherb.olx.ua" className="olx-menu-item">
                        <div className='img-wrapper'><img alt="viber" src="/icons/olx_logo.svg" /></div>
                        <span className='info-show-small'>ОЛХ</span>
                    </Link>
                    <Link to="https://annaiherb.prom.ua/" className='dbg-menu-item'>
                        <div className='img-wrapper'>
                            <svg className='icon' viewBox='0 0 30 30'><use xlinkHref='/icons/iconGroup.svg#prom' /></svg>
                        </div>
                        <span className='info-show-small'>Prom</span>
                    </Link>
                    <Link to="/deliveryandpayment" state={{ navigateBack: true }} className='no-icon'>
                        Оплата та доставка
                    </Link>
                    <Link to="/returnPolicy" state={{ navigateBack: true }} className='no-icon'>
                        Обмін та повернення
                    </Link>
                </div>
            </div>
    </Fragment>

}