import React, { Fragment, useEffect, useState} from 'react';
import {
    useNavigate,
    useSearchParams,
    Link,
    useLocation
} from "react-router-dom";
import { cart } from './Model';
import "./ShoppingCart.css"
import { analyticsLog, fetchSalerToken } from './FirebaseAPI';
import { BackMenuButton, useBackNavigate } from './common';

const baseQrUrl="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="

export function ShoppingMenu(props) {
    return (
        <Fragment>
            <BackMenuButton/>
            <div className='center-div h100'>
                <h2>Кошик</h2>
            </div>
        </Fragment>
    )
}

function ItemRow(props){
    return <div className='cart-item'>
        <div className='name-row'>
            <Link className="transparent-link" to={"/product/" + props.item.id} state={{ navigateBack: true }} />
            <img src={props.item.imgPath} />
            <div>{props.item.name}</div>
            <button className='del-item' onClick={() => props.setCount(props.item.id, 0)}>
                <img src="/icons/x.svg"/>
            </button>
        </div>
        <div className='price-row'>
            <div>Ціна {props.item.price} ₴</div>
            <div className='inc-dec-cart'>
                <button className='inc-dec-btn' disabled={props.count<2} onClick={() => props.setCount(props.item.id, props.count - 1)}>-</button>
                <div >{props.count} шт.</div>
                <button className='inc-dec-btn' onClick={() => props.setCount(props.item.id, props.count + 1)}>+</button>
            </div>
            <div>Cума {props.count * props.item.price} ₴</div>
        </div>
    </div>
}

function cartToSearchStr(){
var strings=[]
for (let [key, value] of cart.elements.entries()) {
strings.push(key+"="+value)
}
    
return strings.join("&");
}

function cartToSearchObj(){
    var searchObj = { "d": true }
    cart.elements.forEach((val, key) => { searchObj[key] = val })
    return searchObj
}

async function sendNotification(phoneVal){
    const url = 'https://service.annaiherb.pp.ua/order';

    var data={}
    data.order={}
    for (let [key, value] of cart.elements.entries()) {
        data.order[key]=value
    }

    data.seller_token = await fetchSalerToken()

    data.phone = "+"+phoneVal.replace(/[^\d]/g, '')

    var data_str = JSON.stringify(data)

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: data_str,
    });


    const text = await response.text();

    if (text=="success"){
        cart.clear()
        return true
    }else
        return false
}

function simpleQuantityValidation(idToItemMap){

    for (let [key, value] of cart.elements.entries()) {
        let item = idToItemMap.get(key)
        if (item === undefined)
            return false
        let quantity = item.quantity
        if (quantity<3 && value>quantity)
            return false
    }
    return true;
}

export function ShoppingCart(props) {
    var loadedPhone = localStorage.phone || ""
    const [phoneVal, setPhoneVal] = useState(loadedPhone);
    const navigate = useNavigate();
    let { state } = useLocation();
    const back = useBackNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    async function buyWithViber(){
        localStorage.phone=phoneVal
        if (!simpleQuantityValidation(props.idToNameMap)){
            alert("Вказаних товарів недостаньо в наявності. Перевірте наявність товарів на сайті.")
            return;
        }

        analyticsLog("by_click")
        if (await sendNotification(phoneVal)){
            navigate("/confirmed")
        }
    }
    function setCount(itemId,quantity){
        cart.setQuantity(itemId, quantity)
        if (cart.isEmpty()){
            console.log("IsEmtpy")
            back()
        }else{
            setSearchParams(cartToSearchObj(), { replace: true, state:state })
        }
    }

    if (searchParams.get("d")){
        let map = new Map();
        searchParams.forEach( (value,key)=>{
            key=Number(key)
            value=Number(value)
            if (value>0 && props.idToNameMap.has(key))
                map.set(key,value);
        })
        cart.setQuantityMap(map)
    }else{
        setTimeout(()=>{
            setSearchParams(cartToSearchObj(),{replace:true, state:state})
        },0)
    }

    if (props.idToNameMap === null) // this should not happen
        return <div>Завантажується</div>
    var el = []
    var sumVal = 0
    for (let [key, count] of cart.elements.entries()) {
        let item = props.idToNameMap.get(key)
        el.push({ item, count })
        sumVal += item.price * count;
    }
    if (el.length == 0) {
        return <div className='table-wrapper empty-cart'>
            <img alt="Empty cart" src="/icons/empty-cart.svg" />
            <div>Кошик порожній</div>
        </div>
    }

    function phoneVAlChange(event){
        var origNewVal= event.target.value
        var newVal=origNewVal.substr(4)
        newVal=newVal.replace(/[^\d]/g,'')
        if (phoneVal.length-origNewVal.length==1 && phoneVal.startsWith(origNewVal) && !/^\d+$/.test(phoneVal.at(origNewVal.length)))
            newVal=newVal.substr(0,newVal.length-1)
        newVal=newVal.substring(0,10)
        if (newVal.length<3)
            setPhoneVal("+38("+newVal)
        else if (newVal.length<6){
            setPhoneVal("+38("+newVal.substr(0,3)+")"+newVal.substr(3))
        }else if (newVal.length<8){
            setPhoneVal("+38("+newVal.substr(0,3)+")"+newVal.substr(3,3)+"-"+newVal.substr(6))
        }else{
            setPhoneVal("+38("+newVal.substr(0,3)+")"+newVal.substr(3,3)+"-"+newVal.substr(6,2)+"-"+newVal.substr(8))
        }
    }
    function phoneFocus(e){
        e.target.scrollIntoView()
        if (phoneVal.length==0)
            setPhoneVal("+38(")
    }
    function phoneFocusOut(){
        if (phoneVal.length==4)
            setPhoneVal("")
    }

    return (
        <div className='table-wrapper'>
            {el.map((r) => <ItemRow key={r.item.id} setCount={setCount} {...r} />)}
            <div className='after-table'>
                <div className="sum-group">
                    <img src="/icons/sum-prefix.svg" />
                    <div>Сума:{sumVal} ₴</div>
                </div>
            </div>
            <div className='order-block'>
                Введіть номер на якому є <b>Viber</b> або <b>Telegram</b>
                <div className='phone-group'>
                    <div className='circle-logo' id="viber">
                        <img alt="shopping cart" src="/icons/viber_logo.svg" />
                    </div>
                    <img id="telegram" src="/icons/telegram_logo.svg" />
                    <input type="tel" id="phone" name="phone" placeholder="+38(xxx)xxx-xx-xx" 
                        value={phoneVal} onChange={phoneVAlChange} 
                        onFocus={phoneFocus} 
                        onBlur={phoneFocusOut} />
                    <button className='btn' onClick={buyWithViber} disabled={phoneVal.length!=17}>
                        Замовити
                    </button>
                </div>
            </div>
        </div>
    )
}
