import React from 'react';

var copyTextAreaRef = React.createRef()

// This component should be placed in application, so it will be in dom when copy to clipboard is required
export function ClipboardHelper(){
    return <textarea hidden={true} ref={copyTextAreaRef}></textarea>
}

// navigator.clipboard.writeText will work only with valid https certificate (see window.isSecureContext)
// copy to clipboard workaround

export function copyToClipboard(text){
    let textArea=copyTextAreaRef.current
    textArea.hidden=false
    textArea.value = text;
    textArea.focus();
    textArea.select(); 
    document.execCommand('copy');
    textArea.hidden=true
}