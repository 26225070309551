import React, { Fragment } from 'react';
import { BackMenuButton } from './common';
import "./About.css"


export function AboutMenu(props){
  return (
    <Fragment>
      <BackMenuButton/>
      <div className='center-div h100'>
        {/* <h2>Оплата та доставка</h2> */}
      </div>
    </Fragment>
  )
}


export function About(props) {
  return <div className='table-wrapper'>
    <div id="about-page" className='text-page'>
      <h2>Вітаю, я Анна, засновниця магазину "AnnaiHerb"</h2>
      <img className="photo" src="/photo.jpg"/>
      П'ять років тому я, як і ви зараз, шукала в інтернеті найкращі 
      вітаміни та харчові добавки для себе та рідних. Зрозумівши, що все це можна
      придбати на американському сайті iHerb, я захопилась цією справою і навіть
      не помітила, як усе переросло до власної справи - інтернет-магазину.
      Щиро люблю те, чим займаюсь і розумію ваші потреби, бо теж щодня є покупцем.

      <h2>Чому мій магазин:</h2>
      <ul>
        <li>
          <b>Найкращі продукти близько:</b><br />
          Ми підтримуємо в наявності найкращі продукти з сайту iHerb.
          У нас є більшість найпопулярніших вітамінів, мінералів, харчових добавок,
          а також натуральної косметики, органічних продуктів і багато іншого.
          Вам не доведеться чекати три тижні доставку з США.
        </li>
        <li>
          <b>Якісний товар:</b><br />
          Ви отримаєте оригінальний товар із захисними мембранами виробника. 
          Термін придатності, склад та інша важлива інформація вказана на кожній баночці. 
        </li>
        <li>
          <b>Швидка та зручна доставка:</b><br />
          Хочеться отримати свою посилку якнайшвидше.
          Тому ми робимо все можливе, аби ваше замовлення було відправлено 
          одразу після купівлі. 
          Якщо ви оформили його до 15:00, ми відправимо його у цей самий день.
          Oбирай зручне поштове відділення, будь то Нова Пошта, Укрпошта чи Meest.
        </li>
        <li>
          <b>Доступні ціни:</b><br />
          Наші ціни конкурентоспроможні. 
          Ми завжди думаємо про те, як ще зменшити ціну за товар для кінцевого покупця.
          Замовляючи товар на сайті, ви економите на комісії, яку закладають торгівельні майданчики.
        </li>
        <li>
          <b>Не переплачуй на доставці:</b><br />
          Ми знаємо, як неприємно платити велику ціну за невелике замовлення. Тому завжди про це
          пам'ятаємо і робимо усе можливе, щоб вартість доставки для вас була якнайнижча.
          Не купуємо за ваш рахунок коробки на пошті, використовуємо пакування чітко
          за розміром і не оцінюємо товар більше, ніж він насправді коштує.
        </li>
        <li>
          <b>Якість обслуговування:</b><br />
          Найкращий показник якості нашої роботи - це задоволені клієнти. 
          Наш магазин має багато позитивних відгуків на <a href='https://annaiherb.prom.ua/ua/testimonials'>Prom</a>, 
          та високий рейтинг на <a href="https://annaiherb.olx.ua">OLX</a>. Ми вдячні за ваші відгуки та пропозиції, аби з вашою допомогою стати ще кращими.
        </li>
      </ul>
    </div>
  </div>
}