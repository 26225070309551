import {
  useNavigate,
} from "react-router-dom";

export function NoMatch() {
    const navigate = useNavigate();

    return <div className='table-wrapper'>
        <p>Сторінка не знайдена.</p>
        <button class="btn" onClick={() => navigate('/')}>Повернутися на головну сторінку</button>
    </div>
}
