import React, { Fragment, useEffect, useState } from 'react';
import {
    useParams,
    useNavigate
} from "react-router-dom";
import './ItemsPage.css'
import {cart} from "./Model"
import "./ItemDetails.css"
import { fetchItemDescription } from './FirebaseAPI';
import { BackMenuButton } from './common';

export function ItemDetailsMenu(props) {
    return (
        <Fragment>
            <BackMenuButton/>
            <div className='center-div h100'>
                <h2>Опис товару</h2>
            </div>
        </Fragment>
    )
}

export function ItemDetails(props){
    let { id } = useParams()
    const navigate = useNavigate()
    const itemData = props.idToNameMap.get(Number(id))

    function onAddToCard (){
        cart.setQuantity(itemData.id,1)
        navigate("/cart")
    }

    const [descText, setDescText] = useState("Завантажується опис...");
    useEffect(()=>{
        async function getDescription(){
            try{
                const txt = await fetchItemDescription(itemData.id)
                setDescText(txt)
            }catch(Exception){
                setDescText("")
            }

        }
        window.scrollTo(0, 0);
        getDescription()
    })
    
    var cartQuantity = cart.get(itemData.id)
    var canAdd = cartQuantity === 0 && itemData.quantity > 0
    var disableMsg = itemData.quantity > 0 ? "Вже у кошику" : "Немає у наявності"

    return <div className='item-details-content' >
        <div className='item-description'>
            <img src={itemData.imgPath} />
            <div>
                <h3>{itemData.name}</h3>
                <div className="description" dangerouslySetInnerHTML={{ __html: descText }} />
                <a href={itemData.url}><strong>Відкрити на iHerb</strong></a>
            </div>
        </div>
        <div className='details-bottom center-div'>
            <div className='details-price'>{itemData.price} грн</div>
            {
                canAdd ?
                    <button className="btn add-to-card" onClick={onAddToCard}>Купити</button>
                    :
                    <button className="btn add-to-card" disabled={true}>{disableMsg}</button>
            }
        </div>
    </div>

}