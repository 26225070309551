// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage, ref, getDownloadURL} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDFRD56k5VR5BVHtWi2tFkLFAD0xRBeSWI",
//   authDomain: "androidnotificationtest-a74db.firebaseapp.com",
//   projectId: "androidnotificationtest-a74db",
//   storageBucket: "androidnotificationtest-a74db.appspot.com",
//   messagingSenderId: "877275252137",
//   appId: "1:877275252137:web:40d70580e03cfe6beb30b8",
//   measurementId: "G-L22L5N6P74"
// };

const firebaseConfig = {
  apiKey: "AIzaSyA6KHvjt-nDOuHLIGg76S7l_rTwHvdTumw",
  authDomain: "annaiherb.firebaseapp.com",
  projectId: "annaiherb",
  storageBucket: "annaiherb.appspot.com",
  messagingSenderId: "410456100732",
  appId: "1:410456100732:web:6dcbe114f34219c3bbdef9",
  measurementId: "G-N54X1V3KQ5"
};

// Antony
// const storageDir="DY0UgMvHdPXIF6MfJXUvPJM7L643/"
// Anna
const storageDir="cW3HcfPGEwVmagPCnFCJwa4KTMO2/"

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const storage = getStorage(app);

async function fetchFile(path){
    const spaceRef = ref(storage, storageDir + path);
    let data_url = await getDownloadURL(spaceRef)
    return await fetch(data_url)
}

export async function fetchDataFile(){
    let resp = await fetchFile('data_out_v2.json')
    return await resp.json()

}

export async function fetchItemDescription(id){
    let resp = await fetchFile('desc/'+id+'.html')
    return await resp.text()
}

export async function fetchSalerToken(){
    let resp = await fetchFile('saler.json')
    return (await resp.json()).token
}

export function analyticsLog(eventName){
  logEvent(analytics,eventName)
}