import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import './App.css';
import {cart} from "./Model"
import { ShoppingCart, ShoppingMenu } from './ShoppingCart';
import { fetchDataFile, analyticsLog } from './FirebaseAPI';
import {ClipboardHelper} from './clipboard'
import { About, AboutMenu } from './About';
import { DeliveryAndPayment, DeliveryAndPaymentMenu } from './DeliveryAndPayment';
import { Confirmed } from './Confirmed';
import { NoMatch } from './Page404';
import { Chocolate } from './Chocolate';
import { ItemDetails, ItemDetailsMenu } from './ItemDetails';
import { ReturnPolicy, ReturnPolicyMenu } from './ReturnPolicy';
import { InfoButton } from './InfoMenu';
import { ItemsTable, CategoryTree } from './ItemsPage';
import { viberUrl } from './common';

function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

class BuyControl extends Component{
  constructor(props){
    super(props)
    this.state={numberOfItems:0}
  }
  componentDidMount(){
    cart.setCallback(this.onQuantiyChange)
  }
  componentWillUnmount(){
    cart.setCallback(null)
  }
  onQuantiyChange=(numberOfItems)=>{
    this.setState({numberOfItems})
  }
  render(){
    return <div className='menu-circle-button'>
      <Link to="/cart" state={{navigateBack:true}}>
      <svg className='icon'><use xlinkHref='/icons/iconGroup.svg#cart'/></svg>
      { this.state.numberOfItems > 0 ? 
      <div className='buy-quantity'>{this.state.numberOfItems}</div>
      : null
      }
      </Link>
      </div>
  }
}

function SandwichMenuButton(props){
  return(
    <div className='show-categories-btn menu-button'>
      <input type="checkbox" name="" id="menuCheckbox" onChange={(e) => { props.setMenuOpen(e.target.value) }} checked={props.menuOpen}/>
      <div className="hamburger-lines">
        <span className="line line1"></span>
        <span className="line line2"></span>
        <span className="line line3"></span>
      </div>
      <div className={'cover'+(props.menuOpen?"":" cover-gone")} onClick={()=>{props.setMenuOpen(false)}}/>
    </div>
  )
}

function SearchBar(props){
  const [inputInFocus, setInputInFocus] = useState(false)
  const inputRef = useRef(null)
  // const [focusOut, setFocusOut] = useState(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1)
  const navigate = useNavigate()
  function onSuggestionSelected(link){
    window.scrollTo({ top: 0 })
    setInputInFocus(false)
    props.onSearchChange("")
  }

  function onDocClick(e){
    if (e.target===inputRef.current)
      return
    setInputInFocus(false)
  }

  useEffect(()=>{
    document.addEventListener("click",onDocClick)
    return function(){ 
      document.removeEventListener("click",onDocClick)
    }
  },[])

  return <div className='search-box'>
      <input ref={inputRef} className='search-input'
        onFocus={() => setInputInFocus(true)}
        onChange={(e) => props.onSearchChange(e.target.value)} value={props.searchStr}

        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            e.target.blur()
            if (selectedSuggestion>=0 && selectedSuggestion<props.suggestions.length){
              navigate("/category/" + props.suggestions[selectedSuggestion])
              onSuggestionSelected(props.suggestions[selectedSuggestion])
            }else{
              setInputInFocus(false)
            }
          }else if (e.key === 'ArrowDown' || e.keyCode === 40){
            if (selectedSuggestion < props.suggestions.length-1)
              setSelectedSuggestion(selectedSuggestion+1)
          }else if (e.key === 'ArrowUp' || e.keyCode === 38){
            if (selectedSuggestion > 0)
              setSelectedSuggestion(selectedSuggestion-1)
          }else{
            setSelectedSuggestion(-1)
          }

        }}
      />
      <svg className="icon search-icon"><use xlinkHref='/icons/iconGroup.svg#search' /></svg>
      {props.searchStr && <button onClick={() => props.onSearchChange("")}>
        <svg className="icon"><use xlinkHref='/icons/iconGroup.svg#x' /></svg>
      </button>
      }
      {inputInFocus && props.suggestions.length > 0 && <div className='suggestion'>
        {props.suggestions.map((cat,idx) => <Link key={cat} to={"/category/" + cat} 
          onClick={()=>onSuggestionSelected(cat)} 
          className={idx === selectedSuggestion ? "sugg-selected" : ""}
        > {cat} </Link>)}
      </div>
      }
    </div>
}


function MenuMain(props){
  return (
    <Fragment>
      <SandwichMenuButton setMenuOpen={props.setMenuOpen} menuOpen={props.menuOpen}/>
      <Link to={viberUrl} className="viber-menu-item additional-viber">
          <div className='img-wrapper'><img alt="viber" src="/icons/viber_logo.svg" /></div>
          +38 063 634 57 23
      </Link>
      <SearchBar onSearchChange={props.onSearchChange} searchStr={props.searchStr} suggestions={props.suggestions}/>
      <BuyControl />
    </Fragment>
  )
}

function customItemSort(a,b){
  if ((a.quantity===0) != (b.quantity===0))
    return b.quantity===0 ? -1 : 1 
  if (a.name === b.name)
    return 0
  return a.name < b.name ? -1 : 1
}

function sortCategories(arr, itemsMap){
  arr.sort( (a,b)=>a.name===b.name? 0 : (a.name < b.name ? -1 : 1) )
  for (let cat of arr){
    var itmesInst=[]
    for (let itemId of cat.itemIds){
      let it=itemsMap.get(itemId)
      if (it)
        itmesInst.push(it)
    }
    itmesInst.sort(customItemSort)
    cat.itemIds=undefined
    cat.items=itmesInst
    sortCategories(cat.childCategories, itemsMap)
  }
}

function getCateList(catList){
  var ret=[]
  function recursive(catList){
    for (let cat of catList){
      ret.push(cat.name)
      recursive(cat.childCategories)
    }
  }
  recursive(catList)
  return ret
}

class App extends Component {
  constructor() {
    super()
    console.log("created");
    this.state = { catList:[], categorys :[], menuOpen:false, idToNameMap: null, searchStr:"", suggestions:[]}
  }

  componentDidMount() {
    this.fetchData()
  }
  fetchData = async ()=>{
    let data = await fetchDataFile()

    let idToNameMap = new Map()

    for (let item of data.items){
      item.words=item.name.toLowerCase().split(' ').filter((w)=>w)
      idToNameMap.set(item.id, item)
    }
    sortCategories(data.categories,idToNameMap)

    this.setState({
      categorys:data.categories, 
      catList: getCateList(data.categories),
      idToNameMap
    })
  }

  setMenuOpen=(val)=>{
    this.setState({menuOpen:val})
  }

  onSearchChange=(value)=>{
    const lowervVal=value.toLowerCase().trim()
    var newSuggestions = []
    if (lowervVal.length>2){
      newSuggestions = this.state.catList.filter((cat)=> cat.toLowerCase().includes(lowervVal))
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    if (!arrayEquals(newSuggestions,this.state.suggestions)){
      this.setState({suggestions:newSuggestions})
    }

    this.setState({searchStr:value})
  }
  onMenuItemSelected = ()=>{
    this.setMenuOpen(false)
    this.onSearchChange("")
  }

  render() {

    if (this.state.idToNameMap==null){
      return <div className='loading'><span className="loader"></span></div>
    }

    return (
      <BrowserRouter>
        <div className="App">
          <nav className="navbar">
            <div className="navbar-container">
              <div id='hiding-header'>
                <div id="hiding-header-container">
                  <Link to="/about" id="logo-link">
                    <img src='/logo.png' alt='Logo' id="logo" />
                    <div id="top-title" className='center-div'><h2>Про магазин</h2></div>
                  </Link>
                  <InfoButton/>
                </div>
              </div>
              <Routes>
                <Route path="/" element={
                  <MenuMain
                    menuOpen={this.state.menuOpen}
                    setMenuOpen={this.setMenuOpen}
                    searchStr={this.state.searchStr}
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions}
                  />
                } />
                <Route path="/category/:catName" element={
                  <MenuMain
                    menuOpen={this.state.menuOpen}
                    setMenuOpen={this.setMenuOpen}
                    searchStr={this.state.searchStr}
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions}
                  />
                } />
                <Route path="/cart" element={<ShoppingMenu/>} />
                <Route path="/product/:id" element={<ItemDetailsMenu/>} />
                <Route path="/about" element={<AboutMenu/>} />
                <Route path="/deliveryandpayment" element={<DeliveryAndPaymentMenu/>} />
                <Route path="/returnPolicy" element={<ReturnPolicyMenu/>} />
              </Routes>
            </div>
          </nav>
          <Routes>
            <Route path="/cart" element={<ShoppingCart idToNameMap={this.state.idToNameMap}/>} />
            <Route path="/" element={
              <ItemsTable
                searchStr={this.state.searchStr}
                categorys={this.state.categorys}
                menuOpen={this.state.menuOpen} 
                onItemSelected={this.onMenuItemSelected}
              />
            } />
            <Route path="/category/:catName" element={
              <ItemsTable
                searchStr={this.state.searchStr}
                categorys={this.state.categorys}
                menuOpen={this.state.menuOpen} 
                onItemSelected={this.onMenuItemSelected}
              />
            } />
            <Route path="/product/:id" element={<ItemDetails idToNameMap={this.state.idToNameMap}/>} />
            <Route path="/about" element={<About/>}/>
            <Route path="/deliveryandpayment" element={<DeliveryAndPayment/>}/>
            <Route path="/returnPolicy" element={<ReturnPolicy/>} />
            <Route path="/confirmed" element={<Confirmed/>}/>
            <Route path="/chocolate" element={<Chocolate/>}/>
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <ClipboardHelper/>
          {/* <ItemDialog item={this.state.categorys[0].items[0]}/> */}
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
