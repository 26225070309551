import {
    useNavigate,
} from "react-router-dom";

export function Confirmed(){
    const navigate = useNavigate();
    return <div className='table-wrapper'>
        <p>Ваше замовлення прийняте. Очікуйте повідомлення.</p>
        <button className="btn" onClick={()=>navigate('/')}>Ok</button>
    </div>
}