import React, { Fragment } from 'react';
import {
  Link,
} from "react-router-dom";
import { BackMenuButton } from './common';

export function ReturnPolicyLink(){
  return <div className="about-link center-div">
    <Link to="/returnPolicy" state={{navigateBack:true}}>
      Обмін та повернення
    </Link>
  </div>
}

export function ReturnPolicyMenu(props){
  return (
    <Fragment>
      <BackMenuButton/>
      <div className='center-div h100'>
        <h2>Обмін та повернення</h2>
      </div>
    </Fragment>
  )
}


export function ReturnPolicy(props) {
  return <div className='table-wrapper'>
    <div className='text-page'>
      <p>Товар належної якості обміну та поверненню не підлягає.</p>
      <p>Компанія керується Законом <a href='http://zakon2.rada.gov.ua/laws/show/1023-12'>«Про захист прав споживачів»</a> у питаннях повернення та обміну товарів належної якості.</p>

      <p>Відповідно до Закону «Про захист прав споживачів», компанія може відмовити споживачеві в обміні і поверненні товарів належної якості,
        якщо вони відносяться до категорій, зазначених у чинному <a href='http://zakon2.rada.gov.ua/laws/show/172-94-%D0%BF'>Переліку непродовольчих товарів належної якості, що не підлягають поверненню та обміну.</a>
      </p>

      <p>Товари належної якості, яким дозволено повернення та обмін, можуть бути повернені протягом 14 днів після отримання покупцем, якщо:</p>

      <ul>
        <li>
          товар не був у вживанні та не має слідів використання споживачем: подряпин, сколів, потертостей, плям і т. п.;
        </li>
        <li>
          товар повністю укомплектований та збережена фабрична упаковка;
        </li>
        <li>
          збережені всі ярлики і заводське маркування;
        </li>
        <li>
          товар зберігає товарний вигляд та свої споживчі властивості.
        </li>

      </ul>
    </div>
  </div>
}