import React, { Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {cart} from "./Model"

const State = {
  Closed: 'Closed',
  Opening: 'Opening',
  Opened: 'Opened',
  Closing: 'Closing'
};

function Category({catName, cat, onItemSelected, level}){
  const [state, setState] = useState(State.Closed)
  const childsRef = useRef()
  function changeState(){
    switch (state){
      case State.Closed:
        setState(State.Opening)
        break
      case State.Opened:
        setState(State.Closing)
        break
    }
  }
  useEffect(()=>{
    if (state===State.Opening){
      childsRef.current.style["height"]=0
      childsRef.current.style["height"]=childsRef.current.scrollHeight+"px"
    }
    if (state===State.Closing){
      console.log("start closing" + childsRef.current.getBoundingClientRect().height)
      childsRef.current.style["height"]=childsRef.current.getBoundingClientRect().height+"px"
      childsRef.current.offsetHeight
      childsRef.current.style["height"]="0px"
    }
  })

  function onTransitionEnd(){
    if (state===State.Opening){
      setState(State.Opened)
      childsRef.current.style["height"] = ''
    }else if (state===State.Closing){
      setState(State.Closed)
    }
  }
  
  var childs = state!==State.Closed ? cat.childCategories : []
  return (
    <div>
      {cat.childCategories.length===0 ? 
      <Link to={"/category/" + cat.name} className={catName===cat.name?"selected-cat":""} onClick={onItemSelected} style={{paddingLeft:level*20}}> {cat.name} </Link> 
      : 
      <span onClick={changeState} style={{paddingLeft:level*20}}>{cat.name}</span>
      }
      <div className='childs' ref={childsRef} onTransitionEnd={onTransitionEnd}> 
        {state!==State.Closed ? <Link to={"/category/" + cat.name} className={catName===cat.name?"selected-cat":""} onClick={onItemSelected} style={{ paddingLeft: (level + 1) * 20 }}><b>Все в категорії</b></Link> : null}
        {childs.map(cat => <Category key={cat.name} catName={catName} cat={cat} onItemSelected={onItemSelected} level={level + 1} />)}
      </div>
    </div>
  )
}

export function CategoryTree({menuOpen, catTree, onItemSelected}){
  let { catName } = useParams()
  return (
    <div className={"menu-items " + (menuOpen ? "" : "closed")}>
      <Link to="/" className={catName===undefined?"selected-cat":""} onClick={onItemSelected} style={{paddingLeft:20}}><b>Всі товари</b></Link> 
      {catTree.map( cat =><Category catName={catName} key={cat.name} cat={cat} onItemSelected={onItemSelected} level={1}/> )}
    </div>
  )
}

function quantityStr(num){
  if (num===0)
    return "Немає в наявності"
  if (num>2)
    return "В наявності"
  return "Залишилось "+num+" шт."
}


function Product(props){
  const [cartQuantity, setCartQuantity] = useState(cart.get(props.itemData.id))
  const [visible, setVisible] = useState(false)

  const containerRef = useRef(null)

  function observerCallback(entries) {
    setVisible(entries[0].isIntersecting)
  }


  function onQuantiyChange(id,quantity) {
    if (id!==props.itemData.id){
      return
    }
    setCartQuantity(quantity)
  }


  useEffect(()=>{
    const observer= new IntersectionObserver(observerCallback, {})
    if (containerRef.current) observer.observe(containerRef.current)
    if (visible){
      cart.itemQuantityChange.on("change", onQuantiyChange)
    }
    const unregisterNeeded=visible
    return function unregister(){
      if (unregisterNeeded){
        cart.itemQuantityChange.removeListener("change", onQuantiyChange)
      }
      if (containerRef.current) observer.unobserve(containerRef.current)
    }
  },[visible])

  var itemData = props.itemData

  var content = null
  if (visible){
    function incQuantity(event){
      event.stopPropagation();
      cart.setQuantity(props.itemData.id, cartQuantity + 1);
      event.stopPropagation()
    }
    function decQuantity(event){
      event.stopPropagation();
      cart.setQuantity(props.itemData.id, cartQuantity - 1);
    }


    let buttons;
    if (itemData.quantity===0){
      buttons=null
    }else if (cartQuantity===0) {
      buttons = <div className='inc-dec-item'>
        <button className="inc-dec-btn" onClick={incQuantity}>+</button>
        </div>
    }else{
      buttons = <div className='inc-dec-item'>
        <button className="inc-dec-btn" onClick={incQuantity}>+</button>
        <div className="item-cart-quantity">{cartQuantity}</div>
        <button className="inc-dec-btn" onClick={decQuantity}>-</button>
      </div>
    }

    content = <Fragment>{itemData.imgPath ? <img className='product-image' alt={itemData.name} src={itemData.imgPath} /> : null}
      <div className="product-bottom-wrapper">
        <div className="product-bottom">
          <div className={itemData.quantity > 2 ? " highQuantity" : " lowQuantity"}>{quantityStr(itemData.quantity)} </div>
          <div className='product-price'>{itemData.price} грн</div>
        </div>
      </div>
      {buttons}
    </Fragment>
  }

  return <div className={"product-wrapper " + (itemData.quantity === 0 ? "out-of-stock" : "")} ref={containerRef}>
      <Link className="transparent-link" to={"/product/" + itemData.id} state={{navigateBack:true}}>
        {itemData.name}
      </Link>
      {content}
  </div>
}

function CategoryItem(name){
  return <Fragment>
    <a id={name} className="anchor"></a>
    <div className='category'>
      <h3>{name}</h3>
    </div>
  </Fragment>
}

function flattenCatTree(categories, outCatList){
  for (var cat of categories){
    flattenCatTree(cat.childCategories, outCatList);
    if (cat.items.length>0){
      outCatList.push(cat)
    }
  }
}
function flattenCat(categories){
      var outCatList = []
      flattenCatTree(categories, outCatList)
      outCatList.sort( (a,b)=> a.name===b.name ? 0 : (a.name>b.name ? 1 : -1))
      return outCatList 
}
function selectCategory(catName, categories){
  if (catName===undefined)
    return flattenCat(categories)
  if (categories===undefined)
    return []
  for (var cat of categories){
    if (cat.name===catName){
      return flattenCat([cat])
    }else{
      var found = selectCategory(catName, cat.childCategories)
      if (found && found.length>0)
        return found
    }
  }
  return []
}

function filterItems(searchStr, categories){
    var filterdItems=[]
    var processed=new Set()
    var keywords=searchStr.split(' ').filter((w)=>w)

    function processCatList(catList){
      for (var cat of catList){
        for (var item of cat.items){
          if (processed.has(item.id))
            continue
          processed.add(item.id)
            if (
              keywords.every(
                (w) => item.words.some(
                  (e) => e.includes(w)
                )
              )
            ){
              filterdItems.push(item)
            }
        }
        processCatList(cat.childCategories)
      }
    }
    processCatList(categories)
    return filterdItems
}

export function ItemsTable({searchStr, categorys, menuOpen, onItemSelected}){
  let { catName } = useParams()

  let loc = useLocation()
  const navigate = useNavigate()
  const [showUpBtn, setShowUpBtn] = useState(false)
  const catList = useMemo(()=>selectCategory(catName,categorys), [catName])
  useEffect(()=>{
    let el=document.getElementById(decodeURI(loc.hash).substring(1))
    if (el != null){
      var t = el.offsetTop
      window.scrollTo({ top: t, behavior: "smooth" })
    }
  },[loc])

  const handleScroll=(e)=>{
    const position = window.scrollY;
    setShowUpBtn( position>1500 )
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  var content=null
  searchStr = searchStr.toLowerCase().trim()
  if (searchStr.length>2){
    var filterdItems=filterItems(searchStr, categorys)
    content=(
        <div className='category-wrapper'>
        { filterdItems.map(d => <Product key={d.id} itemData={d} />) }
        </div>
    )
  }else{
    content= catList.map((c) =>
        <div key={c.name} className='category-wrapper'>
          {CategoryItem(c.name)}
          {c.items.map(d => <Product key={d.id} itemData={d} />)}
        </div>
      )
  }

  return (
    <div className='items-table'>
      <CategoryTree menuOpen={menuOpen} catTree={categorys} onItemSelected={onItemSelected}/>
      <div className='item-table-content'>
        {content}
      </div>
      {
        showUpBtn && <button className="float" onClick={() => {
          navigate("#")
          window.scrollTo({ top: 0, behavior: "smooth" })
        }}>
          <svg className='icon' viewBox='0 0 30 30'><use xlinkHref='/icons/iconGroup.svg#upArrow' /></svg>
        </button>
      }

    </div>
  )
}

