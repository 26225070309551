import { EventEmitter } from 'events';

class Cart{
  constructor(){
    try{
      this.elements = new Map(JSON.parse(localStorage.cart));
    }catch(e){
      this.elements = new Map()
    }
    this.callback=null
    this.itemQuantityChange = new EventEmitter()
    this.itemQuantityChange.setMaxListeners(100)
  }
  get(id){
    return this.elements.get(id) || 0
  }
  setCallback(cb){
    this.callback = cb
    this.sendSum()
  }
  sendSum(){
    if (this.callback==null)
      return
    var sum=0
    for (let value of this.elements.values()) {
      sum+=value
    }
    this.callback(sum)
  }
  saveToSorage(){
    localStorage.cart = JSON.stringify(Array.from(this.elements.entries()));
  }
  setQuantity(id,quantity){
    if (quantity===0)
      this.elements.delete(id)
    else
      this.elements.set(id, quantity)

    this.itemQuantityChange.emit("change",id,quantity)
    this.saveToSorage();
    this.sendSum()
  }
  setQuantityMap(newMap){
    this.elements=newMap
    this.saveToSorage();
    this.sendSum()
  }
  isEmpty(){
    console.log("Elements",this.elements)
    return this.elements.size===0
  }
  clear(){
    for (let key of this.elements.keys()) {
      this.itemQuantityChange.emit("change",key,0)
    }
    this.elements = new Map();
    this.saveToSorage();
    this.sendSum();
  }
}
export var cart=new Cart()


