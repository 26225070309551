import React, { Fragment } from 'react';
import { BackMenuButton } from './common';


export function DeliveryAndPaymentMenu(props){
  return (
    <Fragment>
      <BackMenuButton/>
      <div className='center-div h100'>
        <h2>Оплата та доставка</h2>
      </div>
    </Fragment>
  )
}


export function DeliveryAndPayment(props){
    return <div className='table-wrapper'>
        <div className='text-page'>
            <h2>Доставка</h2>
            <ul>
                <li>Нова пошта</li>
                <li>Укрпошта</li>
                <li>Meest</li>
            </ul>
            Доставку товару оплачує одержувач, відповідно до тарифів обраного поштового оператора.

            <h2>Оплата</h2>
            <ul>
                <li>накладений платіж (з частковою оплатою 150 грн.)</li>
                <li>100% передоплата</li>
            </ul>
            Відправлення посилок проводиться в день оплати, якщо розрахунок був проведений до 14:00.
        </div>
    </div>
}