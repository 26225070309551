import {
  Link,
  useNavigate,
  useLocation
} from "react-router-dom";

export const viberUrl="viber://chat/?text=sss&number=380636345723"

export function useBackNavigate(){
    const navigate = useNavigate()
    let { state } = useLocation();
    
    if (state && state.navigateBack)
        return () => { return navigate(-1) }
    return () => { return navigate("/") }
}

export function BackMenuButton(){
  const back = useBackNavigate()
  return <a className='menu-button' href='#' onClick={() => { back() }}><img src="/icons/back-arrow.svg" /></a>
}